import { Box, Collapse, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import Wallet from '@mui/icons-material/AccountBalanceWalletOutlined';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import React from 'react';
import Bills from '@mui/icons-material/ReceiptLongOutlined';
import Budget from '@mui/icons-material/InsightsOutlined';
import Conversations from '@mui/icons-material/QuestionAnswerOutlined';
import Badge from '@mui/material/Badge';
import { useNavigate, useParams } from 'react-router';


const drawerWidth = 240;
const CustomMenu: React.FC = () => {
    const [subListOrcamento, setSubListOrcamento] = React.useState(true);
    const nav = useNavigate();
    let { conversationId } = useParams();
    const goToBudget = () => {nav(`/chat/${conversationId}/budget`)};
    const goToJournal = () => {nav(`/chat/${conversationId}/journal`)};
    const goToChat = () => {nav(`/chat/${conversationId}`)};
    return (

        <Box sx={{ overflow: 'auto' }}>
            <List>
                <ListItem key={"orcamento"} disablePadding>
                    <ListItemButton onClick={() => setSubListOrcamento(!subListOrcamento)}>
                        <ListItemIcon sx={{ color: "white" }}>
                            <Wallet style={{ color: "rgb(203, 139, 208)" }} />
                        </ListItemIcon>
                        <ListItemText primary={"Financeiro"} />
                    </ListItemButton>
                </ListItem>
                <Collapse in={subListOrcamento} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }} onClick={goToBudget  }>
                            <ListItemIcon sx={{ color: "white" }}>
                                <Budget style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary="Orçamento" color='' />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon sx={{ color: "white" }}>
                                <Bills style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary="Contas a Pagar" color='' />
                            <Badge badgeContent={4} color="error" />
                        </ListItemButton>
                    </List>
                </Collapse>
                <ListItem key={"diário"} disablePadding>
                    <ListItemButton onClick={goToJournal}>
                        <ListItemIcon sx={{ color: "white" }} > 
                            <HistoryEduIcon style={{ color: "rgb(118, 208, 235)" }} />
                        </ListItemIcon>
                        <ListItemText primary={"Diário de Obras"} />
                    </ListItemButton>
                </ListItem>

                <ListItem key={"planejamento"} disablePadding>
                    <ListItemButton>
                        <ListItemIcon sx={{ color: "white" }}>
                            <CalendarMonthIcon style={{ color: "rgb(226, 197, 65)" }} />
                        </ListItemIcon>
                        <ListItemText primary={"Cronograma"} />
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider sx={{ borderColor: "white" }} />
            <List>
                <ListItem key={"orcamento"} disablePadding>
                    <ListItemButton onClick={goToChat}>
                        <ListItemIcon sx={{ color: "white" }}>
                            <Conversations style={{ color: "rgb(203, 139, 208)" }} />
                        </ListItemIcon>
                        <ListItemText primary={"Conversas"} />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );
};

export default CustomMenu;