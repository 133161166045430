import { right } from '@popperjs/core';
import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Avatar } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import Typewriter from 'react-ts-typewriter';
import moment from 'moment';

export interface MessageProps {
    content: string;
    role: string;
    date: Date;
}

const Message: React.FC<MessageProps> = (props:MessageProps) => {
    const classSide = props.role == "user" ? "" : "flex-row-reverse";
    let avatar = props.role != "user" ? "/Designer.png" : "User.png";
    return (
        <Row className={"d-flex "+ classSide}>
        <Card style={{ width: "80%", backgroundColor: "transparent", border: "none"}} >
            <CardHeader className={"d-flex "+ classSide} avatar={<Avatar src={avatar} sx={{ width: 64, height: 64}}>U</Avatar>}
                style={{ backgroundColor: "transparent", border: "none" }}>
            </CardHeader>
            <Card.Body style={{ backgroundColor: "rgba(22,22,22,0.7)", borderRadius: "15px" }}>
                <Card.Title style={{ color: "white" }}><pre style={{width:"100%",overflow:"clip",wordWrap:"break-word","whiteSpace":"pre-wrap"}}>{props.content}</pre></Card.Title>
            </Card.Body>
            <Card.Footer className={"d-flex "+ classSide} style={{ backgroundColor: "transparent", border: "none",color:"white" }}>{moment(props.date).format("DD MMM HH:mm")} </Card.Footer>
        </Card>
        </Row>
    );
};

export default Message;