import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { Button, Container, Row } from 'react-bootstrap';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import ChatIcon from '@mui/icons-material/Chat';
import { Icon } from '@mui/material';

export interface PromptSugestionProps {
    title: string,
    icon: "calendar" | "money" | "history" | "chat",
}
export default function MediaControlCard(props: PromptSugestionProps) {
    const theme = useTheme();
    let icon = <CalendarMonthIcon style={{color:"rgb(226, 197, 65)"}}/>;
    if(props.icon == "money") icon = <AttachMoneyIcon style={{color:"rgb(203, 139, 208)"}}/>;
    if(props.icon == "history") icon = <HistoryEduIcon style={{color:"rgb(118, 208, 235)"}}/>;
    if(props.icon == "chat") icon = <ChatIcon style={{color:"rgb(118, 208, 235)"}}/>;
    return (
        <Button style={{ height: "100px", width: "140px",textAlign:"left",color:"white",fontSize:"15px",backgroundColor:"rgba(22,22,22,0.7)", border: "solid white 1px",alignContent:"start" ,borderRadius: "20px", padding: "10px" }}>
            <Icon style={{ width: "100%",height:"30px",textAlign:"left","color":"rgb(240,191,96"}}>
                {icon}
            </Icon>
            {props.title}
        </Button>
    );
}