import React from 'react';
import Typewriter from 'react-ts-typewriter';
import Button from 'react-bootstrap/Button';
import './button.css';
import { Navigate, useNavigate } from "react-router-dom";
import { Container } from 'react-bootstrap';
import { FirebaseContext } from '../..';
import { logEvent } from 'firebase/analytics';
import axios from 'axios';

const Home: React.FC = () => {
    const conversationId = localStorage.getItem("conversationId");
    const [showButton, setShowButton] = React.useState(false);
    setTimeout(() => {
        setShowButton(true);
    }, 3500);

    const nav = useNavigate();

    const goToChat = () => {
        logEvent(analytics, "Click Chat Button");
        if (!conversationId) {
            axios.get(`https://us-central1-re4me-9579b.cloudfunctions.net/api/startConversation`).then((response) => {
                localStorage.setItem("conversationId", response.data);
                nav(`/chat/${response.data}`);
            });
        }
        else{
            nav(`/chat/${conversationId}`);
        }
    };

    const analytics = React.useContext(FirebaseContext);
    ; return (
        <Container className="contact my-5">
            <img src="Designer.png" width="400px" />
            <div className="sub-title" style={{ width: "30rem", color: 'white' }}>
                <Typewriter text="Olá, sou a Re4me, sua assistente virtual para reformas."
                    speed={60}
                    random={100}></Typewriter>
            </div>
            <Button className='primary slowButton' onClick={() => goToChat()} style={{opacity: showButton?1:0}}>{conversationId ? "Continuar Conversa." : "Vamos conversar?"} </Button>
        </Container>
    );
};

export default Home;