import React from 'react';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router';

export interface StarsProps {
    children?: React.ReactNode;   
}
const Stars = (props:StarsProps) => {
    // Your code here

    return (
        <div>
            <div className="overlay"></div>
            <div className="stars" aria-hidden="true"></div>
            <div className="stars2" aria-hidden="true"></div>
            <div className="stars3" aria-hidden="true"></div>
            <main className="main">
                <Container fluid style={{height:"100%"}}>
                    <Outlet/>
                </Container>
            </main>
        </div>
    );
};

export default Stars;