import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';

type JournalLogs = {
  date: Date;
  title: string;
  description: string;

}
export default function Journal() {
  const [logs, setLogs] = React.useState([{ date: new Date(), title: "Titulo", description: "Descricao" }] as JournalLogs[]);
  return (
    <Timeline position="alternate" sx={{ color: "white", bgcolor: "black", width: "400px", height: "90%", borderRadius: "15px" }}>
      {logs.map((log: JournalLogs) => <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          align="right"
          variant="body2"
          color="text.secondary"
        >
        <Typography sx={{color:"white"}}>{log.date.toDateString()}</Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot>
            <FastfoodIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            {log.title}
          </Typography>
          <Typography>{log.description}</Typography>
        </TimelineContent>
      </TimelineItem>)}


    </Timeline>
  );
}