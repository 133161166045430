import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './stars.css';
import reportWebVitals from './reportWebVitals';
import Stars from './Layout/Stars';
import Home from './Pages/Home/home';
import 'bootstrap/dist/css/bootstrap.min.css';
import './root.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Private } from './Pages/Chat/chat';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAnalytics } from 'firebase/analytics';
import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import PersistentDrawerLeft from './Layout/AppBar/AppBar';
import Journal from './Pages/Journal/journal';
import { Helmet } from 'react-helmet';

const firebaseConfig = {
  apiKey: "AIzaSyDlR8wvgE9j7exFNPeb0l4D0_FJfFBhjtc",
  authDomain: "re4me-9579b.firebaseapp.com",
  projectId: "re4me-9579b",
  storageBucket: "re4me-9579b.appspot.com",
  messagingSenderId: "318421844150",
  appId: "1:318421844150:web:75e0e2289cb170171f6617",
  measurementId: "G-9T1XJCTR3W"
};


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const FirebaseContext = React.createContext(analytics);
export { FirebaseContext };

root.render(
  <React.StrictMode>
    <Helmet>
      <meta property="og:image" content="/Designer.png" />
      <meta property="og:type" content="website" />
      <meta name="description" content="Seu assistente virtual para reformas." />
      <title>Re4me</title>
    </Helmet>
    <FirebaseContext.Provider value={analytics}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Stars />} >
            <Route index element={<Home />} />
            <Route path="/chat/:conversationId" element={<PersistentDrawerLeft />}>
              <Route index element={<Private />} />
              <Route path="/chat/:conversationId/journal" element={<Journal />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </FirebaseContext.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
