import { Container } from "react-bootstrap";
import Message, { MessageProps } from "./message";

export interface MessagesContainerProps {
    messages: Array<MessageProps>;
    waiting: boolean;
}

export default function MessagesContainer(props: MessagesContainerProps) {
    return (<Container className="d-flex justify-content-center" style={{ height: "85%" }}>
        <div className="col-md-12 col-lg-12 col-xl-12 scrollable" id="scrollable">
            {props.messages.map((message, index) => (
                <Message key={index} {...message} />
            ))}
            <div hidden={props.waiting == false}>
                <Message content={"Re4me está pensando"} date={new Date()} role={"system"} />
            </div>
        </div>
    </Container>);
}