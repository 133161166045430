import { Container, Row, Col } from "react-bootstrap";
import MediaControlCard from "./promptSugestion";
import { FC } from "react";

export interface PromptSugestionSectionProps{
    onSelect: (title: string) => void;
    hidden?: boolean;
}

const PromptSugestionSection =function  (props:PromptSugestionSectionProps) {
    const schedule = "Olá, gostaria de iniciar a criação de um cronograma de reforma.";
    const journal = "Olá, gostaria de iniciar a criação de um diário de obra.";
    const budget = "Olá, gostaria de iniciar a criação de um orçamento de reforma."
    const chat = "Olá, tenho algumas dúvidas sobre reforma. Poderia me ajudar?";
    return (
        <Container style={{ height:"85%",justifyContent:"space-between",alignItems:"center",alignContent:"center"}} hidden={props.hidden}>
                <Row>
                    <Col onClick={() => props.onSelect(schedule)}>
                        <MediaControlCard title='Gerar Cronograma' icon="calendar" />
                    </Col>
                    <Col onClick={() => props.onSelect(journal)}>
                        <MediaControlCard title='Criar Diário de Obra' icon="history" />
                    </Col>
                    <Col onClick={() => props.onSelect(budget)}>
                        <MediaControlCard title='Criar Novo Orcamento' icon="money" />

                    </Col>
                    <Col onClick={() => props.onSelect(chat)}>
                        <MediaControlCard title='Conversar com a Re4me' icon="chat" />

                    </Col>
                </Row>
        </Container>
    )
}

export default PromptSugestionSection;