
import { FC, useEffect, useState } from 'react';
import { Button, Container, Form, InputGroup } from 'react-bootstrap';
import "./scrollable.css";
import axios from 'axios';
import { useParams } from 'react-router';
import { Box, CssBaseline, IconButton } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import PromptSgestionSection from './promptSugestionSection';
import MessagesContainer from './messagesContainer';

const url = "https://us-central1-re4me-9579b.cloudfunctions.net/api"
type message = {
  role: string;
  content: string;
  date: Date;
}


const Private: FC = () => {

  let { conversationId } = useParams();

  useEffect(() => {
    axios.get(`${url}/getConversation/${conversationId}`).then((response) => {
      setMessages(response.data.messages as message[] ?? []);
    });
  }, []);

  const [messages, setMessages] = useState<message[]>([]);
  const [wating, setWating] = useState<boolean>(false);

  const [text, setText] = useState("");

  const sendMessage = () => {
    setText("");
    setWating(true);
    sendPrompt(text);
  }

  const scrollToBottom = () => {
    let y = document.getElementById('scrollable')?.scrollHeight ?? 0;
    document.getElementById('scrollable')?.scrollTo({ top: y, behavior: 'smooth' });
  }

  const sendPrompt = (prompt: string) => {
    axios.post(`${url}/sendMessage/${conversationId}`, { message: prompt })
      .then((response) => {
        setMessages(response.data);
        setWating(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages]);

  const principal = messages.length == 0 ? <PromptSgestionSection onSelect={(e) => sendPrompt(e)} /> :
    <MessagesContainer messages={messages} waiting={wating} />
  return (
    <Box sx={{ display: 'flex',height: "95%"}}>
      <CssBaseline />
      <Box component="main" sx={{ flexGrow: 1, display: "flex", justifyContent: "center", alignContent: "center"} }>
        <Box style={{ maxWidth: "700px", overflow: "auto" }} >
          {principal}
          <Container className="d-flex justify-content-center" style={{ height: "60px" }}>
            <InputGroup className="mb-3 textInput" style={{ border: "solid white", borderRadius: "15px", height: "60px" }}>
              <Form.Control
                className="textInput"
                value={text}
                onChange={(e) => setText(e.target.value)}
                type='text'
                placeholder="Digite sua mensagem"
                aria-label="Digite sua mensagem"
                aria-describedby="basic-addon2"

              />
              <Button variant="buttonHidden" id="button-addon2">
                <IconButton aria-label="delete" className='buttonSend' onClick={() => sendMessage()}>
                  <ArrowUpwardIcon fontSize="inherit" />
                </IconButton>
              </Button>
            </InputGroup>
          </Container>
        </Box>
      </Box>
    </Box>
  )
}

export { Private }
